.kapcsolat{
    width: auto;
    background-color: rgba(48, 48, 48, 0.5);
    padding: 1rem 0 0 2rem;
    margin-bottom: 3rem;
}

.kapcsolat h1 {
    font-size: 32px;
    color: #C0FF00;
    letter-spacing: 0.8px;
    font-weight: 700;
    font-family: 'Roboto Slab', serif;
    font-weight: thin;
    text-transform: uppercase;
    padding-bottom: 1.5rem;
    border-bottom: 3px solid #C0FF00;
    width: 97%;
    margin-left: 10px;
    margin-bottom: 3rem;
}

@media screen and (max-width: 767px) {
    .kapcsolat {
        padding: 1rem;
        padding-bottom: 2rem;
        margin-bottom: 2rem;
    }

    .kapcsolat h1 {
        font-size: 24px;
        padding-bottom: 1rem;
        border-bottom-width: 2px;
        margin-left: 0;
        width: 100%;
    }
}