.footer {
  width: auto;
  background-color: rgba(48, 48, 48, 0.5);
  margin: 0px;
  padding-top: 10px;
}

.footer h1 {
  color: white;
  font-family: "Comic Sans MS";
  font-size: 1.2rem;
  letter-spacing: 1px;
}

.footer .flex {
  display: flex;
  padding-bottom: 10px;
}

.footer img {
  padding-left: 20px;
  width: 52px;
  object-fit: contain;
  padding-right: 10px;
}

.footer .text-icon {
  padding-left: 10px;
  width: 65px;
  object-fit: contain;
  padding-right: 10px;
  margin-top: -10px;
}

.footer .viber-icon {
  padding-left: 10px;
  width: 120px;
  object-fit: contain;
  padding-right: 10px;
  margin-top: -10px;
  position: relative;
  top: 0px;
}

.footer .viber-icon:hover {
  cursor: pointer;
  position: relative;
  top: -4px;
  transition: all 0.3s ease-in-out;
}

.footer .number{
  padding-top: 5px;
}

.footer .number:hover {
  cursor: pointer;
  transform-origin: top right;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  font-size: 1.8rem;
  text-decoration: none;
}

.footer .email:hover {
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  transform: scale(1.3);
  transform-origin: top left;
}

.footer a{
    color: white;
  font-family: "Comic Sans MS";
  font-size: 1.2rem;
  letter-spacing: 1px;
  text-transform: none;
  font-weight: 600;
}

.footer .alsoh1{
    padding-left: 25px;
    padding-bottom: 20px;
    margin-top: 0px;
}

.footer .email-img{
  width: 60px;
}

.footer .seo {
  display: none;
}