.navbar {
    width: 100%;
    margin-bottom: 2rem;
    margin-top: 2rem;
}

.navbar .mobil{
    padding-left: 20px;
    width: 60px;
    object-fit: contain;
    padding-right: 15px;
    display: none;
}

.navbar .number {
    color: #C0FF00;
    margin-top: 0px;
    padding-left: 20px;
    font-size: 2.5rem;
    text-decoration: underline;
    display: none;
}

.navbar .viber-icon {
    display: none;
}

.nav {
    display: flex;
    justify-content: center;
}

.nav ul {
    display: flex;
    gap: 15px;
    padding: 20px 0;
    list-style: none;
    justify-content: center;
}

a {
    font-family: "Open Sans", sans-serif;
    text-transform: uppercase;
    font-size: 1.25rem;
    letter-spacing: 1.5px;
    font-weight: 500;
    text-decoration: none;
    color: white;
}

a span {
    position: relative;
    padding: 20px 20px;
}

a:hover span {
    background-color: #C0FF00;
    color: black;
    transition: all 0.25s ease-in-out;
}

a.active span {
    color: #C0FF00;
}

a.active:hover span {
    color: black;
}

.header .icon-div{
    display: flex;
    justify-content: space-between;
    margin-top: -120px;
}

.navbar .header .fa{
    max-width: 300px;
    width: 100%;
    height: auto;
}

.navbar .header .furesz{
    max-width: 500px;
    width: 100%;
    height: auto;
    padding-left: 4rem;
}

.header-h2-div {
    display: flex;
    justify-content: center;
}

.header h1 {
    font-size: 4rem;
    text-transform: uppercase;
    padding: 0;
    margin: 40px 0 10px 0;
    text-align: center;
    font-weight: 800;
    font-family: "Open Sans", sans-serif;
    background: -webkit-linear-gradient(#d8d8d8, #aeaeae);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.header a{
    letter-spacing: 0.2px;
}

.header a:hover{
    cursor: pointer;}

.header h2 {
    font-size: 2rem;
    width: 800px;
    text-align: center;
    color: #f0d08c;
    padding-bottom: 20px;
    font-family: "Open Sans", sans-serif;
    text-shadow: 
    0 0 5px #ffaa0059,  /* Kis méretű árnyék */
    0 0 10px #ffcc0063, /* Közepes méretű árnyék */
    0 0 5px #ff440063, /* Narancssárga árnyék */
    0 0 10px #ff440094; /* Legnagyobb árnyék */
}

.nav ul {
    flex-direction: row;
}

.navbar .flex{
    display: flex;
    justify-content: center;
}

.navbar .menu {
    position: relative;
    margin-left: 20rem;
    margin-top: 2rem;
    margin-bottom: 1.6rem;
    display: none;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    width: 2.25rem;
    height: 1.8rem;
    cursor: pointer;
}

.burger-bar {
    width: 2.25rem;
    height: 0.4rem;
    background-color: white;
    border-radius: 0.2rem;
    transition: transform 0.5s ease-in-out;
}

.burger-bar.clicked:nth-child(1) {
    transform: rotate(45deg) translate(0.5rem, 0.5rem);
}

.burger-bar.clicked:nth-child(2) {
    transform: scale(0);
}

.burger-bar.clicked:nth-child(3) {
    transform: rotate(-45deg) translate(0.5rem, -0.5rem);
}

.navbar .logo{
    width: 120px;
    padding-bottom: 20px;
}

.navbar .logo-div{
    display: flex;
    justify-content: center;
}

.navbar .logo-mobil{
    display: none;
}

@media screen and (max-width: 1270px) {
    .navbar .logo-mobil{
        display: block;
        width: 50px;
        margin-left: 25px;
        margin-top: -10px;
    }
    
    .navbar .logo{
        display: none;
    }
    
    .navbar .logo-div{
        display: none;
    }
    
    .navbar{
        margin-top: 0px;
        padding-top: 0px;
    }

    .navbar .flex{
        display: flex;
        justify-content: left;
        margin-top: -50px;
    }

    .navbar .mobil{
        margin-left: 5px;
        width: 72px;
        object-fit: contain;
        padding-right: 15px;
        display: block;
    }
    
    .navbar .number {
        color: #C0FF00;
        margin-top: 5px;
        padding-left: 10px;
        font-size: 1rem;
        text-decoration: none;
        display: block;
    }

    .navbar .viber-icon {
        display: block;
        width: 65px;
        margin-left: 5px;
        padding-top: 3px;
        object-fit: contain;
    }
    
    .navbar .menu {
        display: flex;
    }

    .nav {
        flex-direction: column;
        align-items: start;
    }

    .nav ul {
        display: none;
        flex-direction: column;
        width: 100%;
        margin: 0.25rem;
    }

    .nav ul.open {
        display: flex;
    }

    .nav ul li {
        width: 100%;
        text-align: center;
    }

    .nav a span {
        padding: 8px;
        font-size: 1.2rem;
    }

    .header .fa, .header .furesz{
        display: none;
    }

    .header h1 {
        font-size: 2rem;
        margin-top: 20px;
        max-width: 400px;
        text-align: center;
        padding-bottom: 10px;
        width: auto;
    }

    .header h2 {
        font-size: 1.2rem;
        width: auto;
        padding: 0 10px 10px 10px;
        max-width: 380px;
        margin-bottom: 0.8rem;
    }
}