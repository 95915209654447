* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  display: flex;
  justify-content: center;
  background-image: url('/src/assets/grey_wood_bg.jpg');
  background-size: cover;
  background-attachment: fixed;
}

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden;
}

@media screen and (max-width: 1270px) {
  body {
    background-image: url('/src/assets/grey_wood_bg_mobile.jpg');
  }
  
}