.ajanlatkeres {
    width: auto;
    background-color: rgba(48, 48, 48, 0.5);
    padding: 1rem 0 0 2rem;
    margin-bottom: 3rem;
}

.ajanlatkeres h1 {
    font-size: 32px;
    color: #C0FF00;
    letter-spacing: 0.8px;
    font-weight: 700;
    font-family: 'Roboto Slab', serif;
    font-weight: thin;
    text-transform: uppercase;
    padding-bottom: 1.5rem;
    border-bottom: 3px solid #C0FF00;
    width: 97%;
    margin-left: 10px;
    margin-bottom: 3rem;
}

.ajanlatkeres .flex{
    display: flex;
    justify-content: center;
}

.ajanlatkeres img{
    max-width: 1000px;
    width: 100%;
    height: auto;
}

.ajanlatkeres .text-icon {
    padding-left: 10px;
    width: 70px;
    object-fit: contain;
    padding-right: 10px;
    margin-top: 0px;
}

.ajanlatkeres .viber-icon {
    padding-left: 10px;
    width: 120px;
    object-fit: contain;
    padding-right: 10px;
    margin-top: 0px;
    position: relative;
    top: 0px;
}

.ajanlatkeres .viber-icon:hover {
    cursor: pointer;
    position: relative;
    top: -4px;
    transition: all 0.3s ease-in-out;
}

.ajanlatkeres .number {
    color: #C0FF00;
    margin-top: 0px;
    padding-left: 20px;
    font-size: 2.5rem;
    text-decoration: underline;
}

.ajanlatkeres .number:hover {
    cursor: pointer;
    transform: scale(1.1);
    transition: all 0.3s ease-in-out;
}

.ajanlatkeres .mobil {
    padding-left: 20px;
    width: 60px;
    object-fit: contain;
    padding-right: 15px;
    margin-top: -10px;
}

.ajanlatkeres h3 {
    color: #FF8C00;
    font-size: 2.8rem;
    text-align: center;
    margin-top: 35px;
    min-width: 200px;
}

.ajanlatkeres h5 {
    color: white;
    font-size: 1.5rem;
    text-align: center;
    text-decoration: underline;
    margin-bottom: 20px;
    margin-top: 20px;
}

.ajanlatkeres .contact {
    color: #C0FF00;
    font-family: "Open Sans", sans-serif;
    font-size: 2.4rem;
    margin-top: 0px;
}

.ajanlatkeres .fotok-kuldese{
    margin-top: 40px;
    padding-bottom: 15px;
    margin-bottom: 0px;
}

.ajanlatkeres p{
    color: white;
    font-size: 1.2rem;
    font-family: 'Trebuchet MS', Geneva, sans-serif;
    letter-spacing: 0.3px;
    padding-bottom: 5px;
    margin-left: 20px;
    margin-top: 25px;
    margin-bottom: 30px;
}

@media screen and (max-width: 767px) {
    .ajanlatkeres {
        padding: 1rem;
        margin-bottom: 2rem;
    }

    .ajanlatkeres h1 {
        font-size: 24px;
        padding-bottom: 1rem;
        border-bottom-width: 2px;
        margin-left: 0;
        width: 100%;
    }

    .ajanlatkeres .flex {
        flex-direction: column;
        align-items: center;
    }

    .ajanlatkeres img {
        max-width: 100%;
        width: auto;
        height: auto;
    }

    .ajanlatkeres .mobil {
        width: 30px;
        padding-left: 0;
        padding-right: 5px;
        margin-top: 0;
    }

    .ajanlatkeres .text-icon, 
    .ajanlatkeres .viber-icon {
        width: 70px;
        padding-left: 5px;
        padding-right: 5px;
    }

    .ajanlatkeres .number, 
    .ajanlatkeres .contact {
        font-size: 1.8rem;
        padding-left: 0;
        text-align: center;
    }

    .ajanlatkeres h3 {
        font-size: 2rem;
        margin-top: 20px;
    }

    .ajanlatkeres h5 {
        font-size: 1.2rem;
        margin-top: 15px;
        margin-bottom: 15px;
    }
}