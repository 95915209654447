.galeria{
    width: auto;
    background-color: rgba(48, 48, 48, 0.5);
    padding: 1rem 0 0 2rem;
    padding-bottom: 3rem;
    margin-bottom: 3rem;
}

.galeria h1 {
    font-size: 32px;
    color: #C0FF00;
    letter-spacing: 0.8px;
    font-weight: 700;
    font-family: 'Roboto Slab', serif;
    font-weight: thin;
    text-transform: uppercase;
    padding-bottom: 1.5rem;
    border-bottom: 3px solid #C0FF00;
    width: 97%;
    margin-left: 10px;
    margin-bottom: 3rem;
}

.galeria h2 {
    color: #C0FF00;
    font-family: "Open Sans", sans-serif;
    font-size: 2rem;
    text-align: center;
    margin-top: 4rem;
    margin-bottom: 2rem;
}

.galeria img{
    max-width: 400px;
    width: 100%;
    height: auto;
    padding: 10px;
}



@media screen and (max-width: 767px) {
    .galeria {
        padding: 1rem;
        padding-bottom: 2rem;
        margin-bottom: 2rem;
    }

    .galeria h1 {
        font-size: 24px;
        padding-bottom: 1rem;
        border-bottom-width: 2px;
        margin-left: 0;
        width: 100%;
    }

    .galeria .img1,
    .galeria .img2,
    .galeria .img3,
    .galeria .img4 {
        width: 100%;
        height: auto;
        max-width: 100%;
        margin-bottom: 1rem;
    }
}