.araink {
    width: auto;
    background-color: rgba(48, 48, 48, 0.5);
    padding: 1rem 0 0 2rem;
    margin-bottom: 3rem;
}

.araink h1 {
    font-size: 32px;
    color: #C0FF00;
    letter-spacing: 0.8px;
    font-weight: 700;
    font-family: 'Roboto Slab', serif;
    font-weight: thin;
    text-transform: uppercase;
    padding-bottom: 1.5rem;
    border-bottom: 3px solid #C0FF00;
    width: 97%;
    margin-left: 10px;
    margin-bottom: 3rem;
}

.araink .flex {
    display: flex;
    justify-content: center;
}

.araink img {
    max-width: 1000px;
    width: 100%;
    height: auto;
}

.araink h2 {
    font-size: 32px;
    color: #C0FF00;
    letter-spacing: 0.8px;
    font-weight: 700;
    font-family: 'Roboto Slab', serif;
    font-weight: thin;
    text-transform: uppercase;
    margin-left: 10px;
    margin-bottom: 0px;
    margin-top: 30px;
}

.araink li {
    color: white;
    font-size: 1.3rem;
    font-family: 'Trebuchet MS', Geneva, sans-serif;
    letter-spacing: 0.3px;
    padding-bottom: 5px;
    margin-left: 50px;
}

.araink p {
    color: white;
    font-family: "Comic Sans MS";
    text-align: center;
    margin-top: 0px;
    margin-bottom: 0px;
}

.araink .alsop {
    padding-bottom: 3rem;
}

/* Mobil nézethez igazítás */
@media screen and (max-width: 767px) {
    .araink {
        padding: 1rem;
        margin-bottom: 2rem;
    }

    .araink h1 {
        font-size: 24px;
        padding-bottom: 1rem;
        border-bottom-width: 2px;
        margin-left: 0;
        width: 100%;
    }

    .araink h2 {
        font-size: 1.8rem;
        margin-top: 20px;
        margin-left: 0;
        text-align: center;
    }

    .araink li {
        font-size: 1.2rem;
        margin-left: 20px;
        padding-bottom: 10px;
    }

    .araink p {
        font-size: 1.4rem;
        padding: 0 15px;
    }

    .araink img {
        max-width: 100%;
        width: auto;
        height: auto;
    }

    .burger-menu {
        top: 10px;  /* Csökkentheted a top margót kisebb képernyőkön */
        right: 10px; /* Csökkentheted a right margót kisebb képernyőkön */
    }
}