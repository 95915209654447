form {
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
    color: white;
    font-family: Arial, sans-serif;
}

label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
}

input[type="text"],
input[type="email"],
textarea {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 20px;
    border-radius: 4px;
    border: 2px solid rgba(232, 232, 232, 0.291);
    background-color: #5555553a;
    color: #4883E3;
    letter-spacing: 1.5px;
    font-weight: 600;
    font-size: 1rem;
    box-shadow: none;
}

input[type="text"]:focus, input[type="email"]:focus{
    outline: none;
    border: 2px solid #4883E3;
    box-shadow: 0px 0px 15px #4883E3;
}

.form-leiras{
    height: 120px;
    outline: none;
}

.form-leiras:focus{
    border: 2px solid #4883E3;
    box-shadow: 0px 0px 15px #4883E3;
}

input[type="file"] {
    margin-bottom: 20px;
}

fieldset {
    border: none;
    margin-bottom: 20px;
}

legend {
    font-weight: bold;
    margin-bottom: 10px;
}

input[type="checkbox"] {
    margin-right: 5px;
}

button {
    background-color: #bfff0000;
    border: solid 2px #C0FF00;
    color: #C0FF00;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    margin-bottom: 30px;
    font-weight: 600;
}

button:hover {
    background-color: #C0FF00;
    color: black;
}

.form-div{
    display: flex;
    justify-content: left;
}

.thank-you-message {
    margin-top: 20px;
    color: #C0FF00;
    font-weight: bold;
    font-size: 16px;
}