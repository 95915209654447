.szolgaltatasok {
    width: auto;
    background-color: rgba(48, 48, 48, 0.5);
    padding: 1rem 0 0 2rem;
    margin-bottom: 3rem;
}

.szolgaltatasok .elso-h1{
    font-size: 32px;
    color: #C0FF00;
    letter-spacing: 0.8px;
    font-weight: 700;
    font-family: 'Roboto Slab', serif;
    font-weight: thin;
    text-transform: uppercase;
    padding-bottom: 1.5rem;
    border-bottom: 3px solid #C0FF00;
    width: 97%;
    margin-left: 10px;
    margin-bottom: 3rem;
    margin-top: -5rem;
}

.szolgaltatasok h1 {
    font-size: 32px;
    color: #C0FF00;
    letter-spacing: 0.8px;
    font-weight: 700;
    font-family: 'Roboto Slab', serif;
    font-weight: thin;
    text-transform: uppercase;
    padding-bottom: 1.5rem;
    border-bottom: 3px solid #C0FF00;
    width: 97%;
    margin-left: 10px;
    margin-bottom: 3rem;
    padding-top: 5rem;
}

.szolgaltatasok h2 {
    color: #C0FF00;
    font-family: "Open Sans", sans-serif;
    font-size: 2rem;
    text-align: center;
    padding-bottom: 1rem;
}

.szolgaltatasok h3 {
    color: #C0FF00;
    font-family: 'Comic Sans MS';
    font-size: 1.3rem;
    text-align: center;
    padding-bottom: 1.5rem;
}

.szolgaltatasok h4 {
    color: #C0FF00;
    font-family: 'Cooper Black', serif;
    font-size: 1.6rem;
    text-align: center;
    padding-bottom: 2rem;
}

.pricing-table {
    width: 100%;
    max-width: 600px;
    margin: 20px auto;
    background-color: none; /* Átlátszó fekete háttér */
    border: 3px solid #C0FF00; /* Az oldalhoz illő szín */
    border-collapse: collapse;
    margin-bottom: 40px;
}

.pricing-table table {
    width: 100%;
    border-collapse: collapse;
}

.pricing-table th,
.pricing-table td {
    border: 1px solid #C0FF00; /* Az oldalhoz illeszkedő szegélyszín */
    padding: 10px;
    text-align: left;
    font-family: 'Open Sans', sans-serif;
    font-size: 1.2rem;
    color: #C0FF00; /* Az oldalhoz illő szövegszín */
}

.pricing-table th {
    text-align: center;
    font-size: 1.5rem;
    background-color: none; /* Sötétebb háttér a fejléchez */
}

.pricing-table td {
    background-color: none; /* Halvány fekete háttér a cellákhoz */
}

.pricing-table td:first-child {
    font-weight: bold;
}

.szolgaltatasok .illusztracio{
    max-width: 500px;
    width: 100%;
    height: auto;
}

.szolgaltatasok .tusko1 {
    max-width: 500px;
    width: 100%;
    height: auto;
}

.szolgaltatasok .tusko2 {
    max-width: 500px;
    width: 100%;
    height: auto;
}

.szolgaltatasok .flex {
    display: flex;
    justify-content: center;
    gap: 30px;
    padding-bottom: 30px;
}

.szolgaltatasok .flex2 {
    display: flex;
    justify-content: center;
    gap: 30px;
    padding-bottom: 3rem;
}

.szolgaltatasok .mobil {
    padding-left: 20px;
    width: 60px;
    object-fit: contain;
    padding-right: 12px;
    margin-top: -12px;
}

.szolgaltatasok .text-icon {
    padding-left: 10px;
    width: 70px;
    object-fit: contain;
    padding-right: 5px;
    margin-top: -15px;
}

.szolgaltatasok .viber-icon {
    padding-left: 10px;
    width: 120px;
    object-fit: contain;
    padding-right: 10px;
    margin-top: 8px;
    position: relative;
    top: 0px;
}

.szolgaltatasok .viber-icon:hover {
    cursor: pointer;
    position: relative;
    top: -4px;
    transition: all 0.3s ease-in-out;
}

.szolgaltatasok .number {
    color: #C0FF00;
    margin-top: 0px;
    padding-left: 20px;
    font-size: 2.5rem;
    text-decoration: underline;
}

.szolgaltatasok .number:hover {
    cursor: pointer;
    transform: scale(1.1);
    transition: all 0.3s ease-in-out;
}

.szolgaltatasok h5 {
    color: white;
    font-size: 1.5rem;
    text-align: center;
    text-decoration: underline;
    margin-bottom: 25px;
    margin-top: 20px;
}

.szolgaltatasok .contact {
    color: #C0FF00;
    font-family: "Open Sans", sans-serif;
    font-size: 2.4rem;
    margin-top: 10px;
}

/* Mobil nézethez igazítás */
@media screen and (max-width: 767px) {
    .szolgaltatasok {
        padding: 1rem;
        margin-bottom: 2rem;
    }

    .szolgaltatasok h1 {
        font-size: 24px;
        padding-bottom: 1rem;
        border-bottom-width: 2px;
        margin-left: 0;
        width: 100%;
        padding-top: 2rem;
    }

    .szolgaltatasok .elso-h1 {
        font-size: 24px;
        padding-bottom: 1rem;
        border-bottom-width: 2px;
        margin-left: 0;
        width: 100%;
        padding-top: 5rem;
    }

    .szolgaltatasok h2 {
        font-size: 1.8rem;
        text-align: center;
    }

    .szolgaltatasok h3 {
        font-size: 1.5rem;
        padding-bottom: 3rem;
        text-align: center;
    }

    .szolgaltatasok h4 {
        font-size: 1.4rem;
        padding-bottom: 2rem;
        text-align: center;
    }

    .pricing-table {
        max-width: 100%;
    }
    
    .pricing-table th,
    .pricing-table td {
        font-size: 1rem;
        padding: 8px;
    }
    
    .pricing-table th {
        font-size: 1.2rem;
    }

    .szolgaltatasok .flex {
        flex-direction: column; 
        align-items: center;
        gap: 0px;
    }

    .szolgaltatasok .tusko1,
    .szolgaltatasok .tusko2 {
        max-width: 90%;
        width: 90%;
    }

    .szolgaltatasok .illusztracio {
        max-width: 90%; 
        width: 90%;
    }

    .szolgaltatasok .flex2 {
        display: block;
        justify-content: center;
        margin-left: 30px;
    }

    .szolgaltatasok .mobil {
        width: 32px;
        padding-left: 0;
        padding-right: 5px;
        margin-top: 0;
        margin-bottom: 10px;
    }

    .szolgaltatasok .text-icon, 
    .szolgaltatasok .viber-icon {
        width: 80px;
        padding-left: 5px;
        padding-right: 5px;
        padding-top: 10px;
    }

    .szolgaltatasok .number, 
    .szolgaltatasok .contact {
        font-size: 1.8rem;
        padding-left: 0;
        text-align: center;
        padding-bottom: 15px;
    }
}