/* ScrollToTopButton.css */
.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.scroll-button {
  background: rgba(100, 100, 100, 0.4);
  color: black;
  border: none;
  border-radius: 4px;
  padding: 7px 14px 11px 14px;
  font-size: 24px;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.scroll-button:hover {
    background: #C0FF00;
    transition: all 0.3s ease-in-out;
}

.scroll-button img {
  width: 15px;
  color: white;
}
