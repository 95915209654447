.cookie-consent-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%; 
    background-color: #333;
    color: white;
    padding: 15px;
    text-align: center;
    display: flex;
    justify-content: center; 
    align-items: center;
    z-index: 1000;
    box-sizing: border-box; 
  }
  
  .cookie-consent-banner p {
    margin: 0;
    padding-right: 15px;
    font-family: "Open Sans", sans-serif;
    font-size: 15px;
    font-weight: 600;
  }
  
  .cookie-consent-banner button {
    font-family: "Open Sans", sans-serif;
    background-color: #C0FF00;
    color: black;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    margin: 0;
  }
  
  .cookie-consent-banner button:hover {
    background-color: rgb(104, 196, 18);
    color: white;
    transition: all 0.2s ease-in-out;
  }
  
  @media (max-width: 600px) {
    .cookie-consent-banner {
      flex-direction: column; 
      padding: 10px;
    }
  
    .cookie-consent-banner p {
      padding: 0;
      margin-bottom: 10px;
    }
  }  