.kezdolap {
    width: auto;
    background-color: rgba(48, 48, 48, 0.5);
    padding: 1rem 0 0 2rem;
    margin-bottom: 3rem;
}

.kezdolap .icon-div{
    display: flex;
    justify-content: space-between;
    margin-top: -120px;
}

.kezdolap .header{
    margin-bottom: 4rem;
}

.kezdolap .header .fa{
    max-width: 300px;
    width: 100%;
    height: auto;
    padding-top: 40px;
}

.kezdolap .header .furesz{
    max-width: 390px;
    width: 100%;
    height: 210px;
    padding-left: 4rem;
    padding-top: 30px;
    margin-top: 40px;
}

.kezdolap .header-h2-div {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0;
    margin: 0 auto;
}

.kezdolap .header h1 {
    font-size: 4rem;
    text-transform: uppercase;
    padding: 0;
    margin: 35px 0 30px 0;
    text-align: center;
    font-weight: 800;
    font-family: "Open Sans", sans-serif;
    background: -webkit-linear-gradient(#d8d8d8, #aeaeae);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    border: none;
}

.kezdolap .header a{
    letter-spacing: 0.2px;
}

.kezdolap .header a:hover{
    cursor: pointer;}

.kezdolap .header h2 {
    font-size: 2rem;
    max-width: 100%;
    text-align: center;
    color: #f0d08c;
    padding: 0 10px;
    margin: 0px;
    font-family: "Open Sans", sans-serif;
    text-shadow: 
    0 0 5px #ffaa0059,  /* Kis méretű árnyék */
    0 0 10px #ffcc0063, /* Közepes méretű árnyék */
    0 0 5px #ff440063, /* Narancssárga árnyék */
    0 0 10px #ff440094; /* Legnagyobb árnyék */
    line-height: 36px;
}

.kezdolap h1 {
    font-size: 32px;
    color: #C0FF00;
    letter-spacing: 0.8px;
    font-weight: 700;
    font-family: 'Roboto Slab', serif;
    font-weight: thin;
    text-transform: uppercase;
    padding-bottom: 1.5rem;
    border-bottom: 3px solid #C0FF00;
    width: 97%;
    margin-left: 10px;
    margin-bottom: 3rem;
}

.kezdolap h2 {
    color: #DAA520;
    font-size: 2.8rem;
    text-align: center;
    margin-bottom: 8px;
    line-height: 52px;
}

.kezdolap h3 {
    color: #FF8C00;
    font-size: 2.8rem;
    text-align: center;
    margin-top: 35px;
    min-width: 200px;
}

.kezdolap .container {
    display: flex;
    justify-content: center;
    width: auto;
    margin-top: 1.3rem;
}

.kezdolap .container img{
    width: 200px;
    padding-bottom: 20px;
}

.kezdolap .container h4{
    width: 200px;
    padding-top: 30px;
}

.kezdolap .container h4 {
    color: #DAA520;
    font-size: 2.8rem;
    font-family: 'Comic Sans MS';
    margin-bottom: 20px;
}

.kezdolap h5 {
    color: white;
    font-size: 1.5rem;
    text-align: center;
    text-decoration: underline;
    margin-bottom: 20px;
    margin-top: 20px;
}

.kezdolap .contact {
    color: #C0FF00;
    font-family: "Open Sans", sans-serif;
    font-size: 2.4rem;
    margin-top: 0px;
}

.kezdolap .infos {
    display: flex;
    justify-content: center;
    width: auto;
}

.kezdolap p {
    color: white;
    font-size: 2rem;
    text-align: center;
    font-weight: 600;
    font-family: 'Comic Sans MS';
    max-width: 1000px;
    padding: 20px 15px 40px; 
}

.kezdolap .felsop{
    padding-top: 50px;
}

.kezdolap h4 {
    color: #C0FF00;
    font-family: "Open Sans", sans-serif;
    font-size: 1.6rem;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 0px;
}

.kezdolap .grid {
    width: auto;
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(auto-fit, 400px);
    gap: 20px;
    padding-bottom: 50px;
    margin-bottom: 60px;
    margin-top: 3rem;
}

.kezdolap .grid .grid-item {
    border: 1px solid #C0FF00;
    border-radius: 8px;
    box-shadow: 0 0 10px #bfff0069;  

}

.kezdolap .grid h3 {
    color: white;
    font-family: "Open Sans", sans-serif;
    font-weight: 500;
    font-size: 2rem;
    margin: 30px auto 15px auto;
    padding: 0px;
}

.kezdolap .grid h4 {
    color: white;
    font-size: 1.1rem;
    font-weight: 500;
    font-family: 'Comic Sans MS';
    text-align: center;
    padding: 0 30px 20px 30px;
}

.kezdolap .flex {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.kezdolap .mobil {
    padding-left: 20px;
    width: 60px;
    object-fit: contain;
    padding-right: 15px;
}

.kezdolap .text-icon {
    padding-left: 10px;
    width: 70px;
    object-fit: contain;
    padding-right: 10px;
    margin-top: 0px;
}

.kezdolap .viber-icon {
    padding-left: 10px;
    width: 120px;
    object-fit: contain;
    padding-right: 10px;
    margin-top: 0px;
    position: relative;
    top: 0px;
}

.kezdolap .viber-icon:hover {
    cursor: pointer;
    position: relative;
    top: -4px;
    transition: all 0.3s ease-in-out;
}

.kezdolap .number {
    color: #C0FF00;
    margin-top: 0px;
    padding-left: 20px;
    font-size: 2.5rem;
    text-decoration: underline;
}

.kezdolap .number:hover {
    cursor: pointer;
    transform: scale(1.1);
    transition: all 0.3s ease-in-out;
}

/* Mobil nézethez igazítás */
@media screen and (max-width: 767px) {
    .kezdolap {
        padding: 1rem;
        margin-bottom: 2rem;
    }

    .kezdolap .header h1{
        font-size: 1.4rem;
        margin-top: 30px;
    }

    .kezdolap .header h2{
        font-size: 1.2rem;
        margin-top: 0px;
        padding-bottom: 110px;
        margin-bottom: 0px;
        text-align: center;
        line-height: 24px;
    }

    .kezdolap h1 {
        font-size: 24px;
        padding-bottom: 1rem;
        border-bottom-width: 2px;
        margin-left: 0;
        width: 100%;
        margin-top: 0px;
    }

    .kezdolap h2, 
    .kezdolap h3 {
        font-size: 2rem;
        margin-top: 20px;
    }

    .kezdolap .flex {
        flex-direction: column;
        align-items: center;
    }

    .kezdolap .grid {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        gap: 10px;
    }

    .kezdolap .grid h3 {
        font-size: 1.5rem;
    }

    .kezdolap .grid h4 {
        font-size: 1rem;
        padding: 0 20px 15px 20px;
    }

    .kezdolap .mobil {
        width: 32px;
        padding-left: 0;
        padding-right: 5px;
        margin-top: 0;
        margin-bottom: 10px;
    }

    .kezdolap .text-icon, 
    .kezdolap .viber-icon {
        width: 80px;
        padding-left: 5px;
        padding-right: 5px;
    }

    .kezdolap .number, 
    .kezdolap .contact {
        font-size: 1.8rem;
        padding-left: 0;
        text-align: center;
    }

    .kezdolap p {
        font-size: 1.2rem;
        padding: 15px 10px 30px;
        font-family: 'Comic Sans MS';
    }

    .kezdolap h4 {
        font-size: 1.4rem;
        padding-top: 25px;
    }

    .kezdolap h5 {
        font-size: 1.1rem;
        margin-top: 15px;
        margin-bottom: 20px;
    }

    .kezdolap .container img{
        width: 150px;
    }

    .kezdolap .container h4{
        padding-top: 25px;
        padding-bottom: 0px;
        font-size: 2rem;
    }
}