.contact-form {
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
    color: white;
    font-family: Arial, sans-serif;
}

.contact-form label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
}

.contact-form input[type="text"],
.contact-form input[type="email"],
.contact-form textarea {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 20px;
    border-radius: 5px;
    border: 2px solid rgba(232, 232, 232, 0.586);
    background-color: #5555553a;
    color: #4883E3;
    box-shadow: none;
}

.contact-form input[type="text"]:focus, .contact-form input[type="email"]:focus{
    outline: none;
    border: 2px solid #4883E3;
    box-shadow: 0px 0px 15px #4883E3;
}

.contact-form textarea {
    height: 120px;
}

.contact-form textarea:focus {
    outline: none;
    border: 2px solid #4883E3;
    box-shadow: 0px 0px 15px #4883E3;
}

.contact-form button {
    background-color: #bfff0000;
    border: solid 2px #C0FF00;
    color: white;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-bottom: 30px;
    font-weight: 700;
}

.contact-form button:hover {
    background-color: #C0FF00;
    color: black;
}

.thank-you-message {
    margin-top: 20px;
    color: #C0FF00;
    font-weight: bold;
    font-size: 16px;
}

.contact-form-div {
    display: flex;
    justify-content: left;
}

.form-div{
    display: grid;
    justify-content: left;
}